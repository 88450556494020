import { useRef } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import { format } from 'date-fns';

const belongLogo =
  'https://res.cloudinary.com/belong/image/upload/v1643351865/uploaded_resources/logo_orzfoc.png';

const VgaCompleteCertificate = ({
  batchData,
  isAllTaskDone,
}: //   company_name,
{
  batchData: any;
  isAllTaskDone: boolean;
  //   company_name: string;
}) => {
  const userDetails = batchData.users[0];
  const completionStatus =
    userDetails.completionStatus === 'Not Applicable' && isAllTaskDone
      ? 'Completion'
      : userDetails.completionStatus;
  const pdfExportComponent = useRef<any>(null);
  const contentArea = useRef(null);
  const handleExportWithMethod = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const areaText = userDetails.area.title;

  // company name modified to have company2 in case company.name is absent
  const companyName =
    (userDetails?.company2?.name
      ? userDetails.company2.name
      : userDetails.company.name) + ' ';

  const companyLogo =
    userDetails.company2?.logoUrl || userDetails.company?.logoUrl;
  const topRightLogo =
    batchData.type === 'University' ? belongLogo : batchData.logoUrl;
  const leftLogo = batchData.type === 'University' ? companyLogo : belongLogo;

  const durationText = ` ${format(
    new Date(batchData.startDate),
    'do LLLL y'
  )} to ${format(new Date(batchData.endDate), 'do LLLL y')}.`;

  return (
    <>
      {completionStatus === 'Not Applicable' ? (
        <div className='m-6'>
          <p className='text-primary text-2xl font-bold mb-4 mt-4 text-center'>
            Thank you for submitting your feedback.
          </p>
          <div className='text-center mb-6'>
            Please submit all the company project tasks and assignments to
            access your VGA Certificate.
          </div>
        </div>
      ) : (
        <>
          <div
            style={{ minWidth: '950px', maxWidth: '1000px' }}
            className='md:w-3/4 md:mx-auto px-4 border-2 border-t-0 border-b-0 pb-4 bg-gray-50'>
            <PDFExport
              paperSize='auto'
              landscape={true}
              ref={pdfExportComponent}>
              <div ref={contentArea} className='flex justify-center'>
                <div
                  className='bg-no-repeat'
                  style={{
                    backgroundImage:
                      "url('https://res.cloudinary.com/belong/image/upload/v1643358849/uploaded_resources/borders_svhcan.png')",
                  }}>
                  <div className='p-6 my-3 bg-transparent'>
                    <div className='flex justify-end mb-6 pr-12 md:pt-2'>
                      <img
                        src={topRightLogo}
                        alt='Logo'
                        className='h-8 md:h-12'
                      />
                    </div>
                    <div className='flex justify-center mb-6 sm:mb-2 md:mb-1 md:-mt-20 -mt-16'>
                      <img
                        src='https://res.cloudinary.com/belong/image/upload/v1643358778/uploaded_resources/badge_fec7cf.png'
                        alt='Logo'
                        className='h-12 md:h-24'
                      />
                    </div>
                    <div className='flex flex-col justify-center px-12 '>
                      <p className='text-2xl font-medium  mb-2 sm:mb-1  text-center text-primary-darkblue tracking-widest'>
                        CERTIFICATE FOR FULL-TIME VGA <br />
                        {/* (6-Month Internship) */}
                      </p>
                      <span className='px-96'>{''}</span>
                      <p className=' font-semibold text-center text-lg  sm:mb-2 mb-4 tracking-widest text-primary-darkblue'>
                        THIS CERTIFICATE IS PRESENTED TO
                      </p>
                      <div className='flex justify-center items-center '>
                        <div className='flex items-center space-x-16 border-b-2'>
                          <BiLeftArrow className='text-primary-mediumblue' />
                          <p className='tracking-widest text-2xl font-bold font-abhaya1'>
                            {userDetails.name.toUpperCase()}
                          </p>
                          <BiRightArrow className='text-primary-mediumblue' />
                        </div>
                      </div>

                      <div>
                        <p className='text-neutral-900 my-6 '>
                          in recognition of their outstanding performance and
                          dedication towards the completion of the{' '}
                          <span className='font-bold'>
                            Virtual Internship with {companyName}{' '}
                          </span>{' '}
                          <span className='text-white'> _ </span> from{' '}
                          <span className='font-bold'>{durationText}</span>
                          We commend their commitment to personal growth and
                          skill development. We believe they have the potential
                          to achieve significant excellence in the field of{' '}
                          <span className='font-bold'>{areaText}</span>
                        </p>
                        <p className='text-2xl text-primary font-semibold font-abhaya1 '>
                          Congratulations on this Achievement !
                        </p>
                      </div>
                    </div>
                    <div className='flex justify-between px-16 my-10'>
                      <div className='mt-8'>
                        <img src={leftLogo} alt='Logo' className='h-8' />
                      </div>

                      <div className='flex flex-col items-center'>
                        <img
                          src='https://res.cloudinary.com/belong/image/upload/v1643353725/uploaded_resources/Deviare_sign_g7kcav.jpg'
                          alt='Logo'
                          className='h-10'
                        />
                        <p className='border-2 border-b-0 border-l-0 border-r-0 border-gray-600 font-bold tracking-wider text-gray-600 pt-2 text-center text-sm mr-2'>
                          DEVIARE DIRECTOR
                        </p>
                      </div>

                      <div className='flex flex-col items-center'>
                        <img
                          src={
                            userDetails.coach.coachSign ||
                            userDetails.mentor.mentorSign
                          }
                          alt='Logo'
                          className='h-10'
                        />
                        <p className='border-2 md:border-2 md:border-b-0 md:border-l-0 md:border-r-0 border-b-0 border-l-0 border-r-0 border-gray-600 font-bold tracking-wider text-gray-600 pt-2 text-center text-xs md:text-sm mr-2 md:mr-0'>
                          INDUSTRY MENTOR
                        </p>
                      </div>

                      <div className='flex flex-col items-center'>
                        <div className='flex flex-col sm:flex-row'>
                          <img
                            src='https://res.cloudinary.com/belong/image/upload/v1643353663/uploaded_resources/One_no4yyb.gif'
                            alt='Logo'
                            className='h-10'
                          />
                          <img
                            src='https://res.cloudinary.com/belong/image/upload/v1643353575/uploaded_resources/Two_yy5ee6.gif'
                            alt='Logo'
                            className='h-10'
                          />
                        </div>
                        <p className='border-2 md:border-2  md:border-b-0 md:border-l-0 md:border-r-0 border-b-0 border-l-0 border-r-0 border-gray-600 font-bold tracking-wider text-gray-600 pt-2 text-center text-xs md:text-sm'>
                          BELONG FOUNDERS
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PDFExport>
          </div>
          <div className='flex flex-col items-center p-2 border-2 border-b-0 bg-gray-50'>
            <button
              onClick={handleExportWithMethod}
              className='rounded mt-3 text-xs text-gray-100 font-bold md:text-sm px-4 md:px-8 py-2 bg-primary hover:bg-primary-light hover:text-gray-700  transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105'>
              Download Certificate
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default VgaCompleteCertificate;
